<template>
  <cn-default-page image="s001.jpg" title="Ich bin im Ruhestand!" subtitle="">
    <h6>
      <span>Ein neues Kapitel beginnt!</span>
    </h6>
    <p>
      Nach vielen wunderbaren Jahren ist es für mich an der Zeit, in den
      wohlverdienten Ruhestand zu gehen. Ich blicke mit Freude auf die
      gemeinsame Zeit zurück und danke Ihnen von Herzen für Ihr Vertrauen und
      Ihre Treue!
    </p>
    <p>
      Doch keine Sorge – Sie sind weiterhin in besten Händen! Meine
      Nachfolgerin, Sandra Messmer, freut sich darauf, Sie im gleichen Studio
      willkommen zu heissen. Besuchen Sie ihre Webseite unter:
      <a href="https://sandra-cosmetic-styling.ch/"
        >https://sandra-cosmetic-styling.ch</a
      >, um Termine zu vereinbaren.
    </p>
    <p>
      Vielen Dank für all die schönen Momente – und alles Gute für die Zukunft!
      😊
    </p>
    <h6>
      <span>Nina Goetschi</span>
    </h6>
    <hr />
  </cn-default-page>
</template>

<script lang="ts">
import CnDefaultPage from "@/components/CnDefaultPage.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnDefaultPage,
  },
});
</script>

<style lang="scss" scoped>
.feedbacks {
  padding-top: 5rem;
}
</style>
