import { menuStore } from "@/use/useMenu";
import HomeView from "@/views/HomeView.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const siteName = "cosmetic-nina";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "selected",
  routes,
});

router.beforeEach(() => {
  if (menuStore.isOpen) {
    menuStore.toggle();
  }
});

router.afterEach((to) => {
  if (to.name) {
    const name = to.name.toString() + " - " + siteName;
    document.title = name;
  } else {
    document.title = siteName;
  }
});

export default router;
