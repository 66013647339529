<template>
  <nav class="mainmenu" :class="{ open: menuStore.isOpen }">
    <header>
      <figure>
        <router-link to="/"
          ><img src="@/assets/images/Logo@2x.jpg" alt="Logo"
        /></router-link>
      </figure>
    </header>
    <div class="items">
      <slot />
    </div>
    <footer>&copy; {{ year }} copyright cosmetic nina. <br /></footer>
  </nav>
</template>

<script lang="ts">
import { menuStore } from "@/use/useMenu";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      menuStore,
      year: new Date().getFullYear(),
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout";

.mainmenu {
  @include MG(Desktop) {
    width: 19%;
    min-width: 20rem;
    max-width: 30rem;
    left: 0;
    height: 100%;
    top: 0;
  }

  position: relative;
  float: left;
  background-color: var(--color-orange);
  z-index: 100;
  width: 100%;
  overflow-y: auto;
  height: calc(100% - var(--miniheader-height));
  left: -102%;
  transition: all 0.5s ease-out;

  &.open {
    left: 0;
  }

  & header figure {
    @include MG(Desktop) {
      padding-right: var--(border-size);
      display: block;
    }

    padding-right: 5px;
    display: none;
    margin: auto;
    width: 100%;

    img {
      width: 100%;
    }
  }

  footer {
    text-align: center;
    bottom: 5px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-transform: uppercase;
    font-size: 0.8rem;

    a {
      color: var(--color-black);
    }
  }
}
</style>
