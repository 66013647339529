<template>
  <ul>
    <li>
      <router-link to="/">RUHESTAND</router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout";
li {
  @include MG(Desktop) {
    text-align: right;
  }
  list-style-type: none;
  text-align: center;
  font-size: 16px;
  font-weight: 800;

  &:hover {
    a {
      color: var(--color-white);
    }
  }

  a {
    color: var(--color-black);
    margin: 0px;
    padding: 1.6rem;
    display: block;
    width: 100%;
    height: 100%;

    &.selected {
      transition: all 0.5s;
      color: var(--color-white);
      background-color: var(--color-gray);
    }
  }
}
</style>
